import React from 'react';

import { graphql } from 'gatsby';

import Footer from '../components/Footer/Footer';
import MaxWidthWrapper from '../components/MaxWidthWrapper';
import styled from 'styled-components';
import Sidebar from '../components/Sidebar';
import DevelopmentOnly from '../components/Development-Only';
import SEO from '../components/SEO';
import SubscribeForm from '../components/SubscribeForm/SubscribeForm';
import Header from '../components/Header/header';

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 120px;
`;
export default ({ data, location }) => {
  return (
    <>
      <SEO title="Subscribe to George Nance's newsletter" description=""></SEO>
      <Header />
      <MaxWidthWrapper>
        <BodyWrapper>
          <SubscribeForm></SubscribeForm>
        </BodyWrapper>
      </MaxWidthWrapper>
      <Footer></Footer>
    </>
  );
};
